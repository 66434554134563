import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Destination from "./pages/destination";
import Tours from "./pages/tours";
import About from "./pages/about";
import Error404 from "./pages/404";
import PackageDetail from "./pages/PackageDetail";
import Blog from "./pages/blog";
import Contact from "./pages/contact";
import Login from "./pages/login";
import DashboardHome from "./pages/DashboardHome";
import ForgotPassword from "./pages/ForgotPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Header from "./components/header";
import Footer from './components/footer';
import ScrollToTopButton from './components/shared/ScrollToTopButton';
import ComingSoon from "./pages/comingSoon";

function App() {
  let page;
  // eslint-disable-next-line default-case
  switch (window.location.pathname) {
    case "/":
      page = <Home />
      break
    case "/home":
      page = <Home />
      break
    case "/destination":
      page = <Destination />
      break
    case "/tours":
      page = <Tours />
      break
    case "/about":
      page = <About />
      break
    case "/tours/package-detail":
      page = <PackageDetail />
      break
    case "/contact":
      page = <Contact />
      break
    case "/blog":
      page = <Blog />
      break
    case "/privacy-policy":
      page = <PrivacyPolicy />
      break
    case "/terms-and-conditions":
      page = <TermsAndConditions />
      break
    case "/admin/login":
      page = <Login />
      break
    case "admin/login/forgot":
      page = <ForgotPassword />
      break
    case "*":
      page = <Error404 />
      break
  }

  return (
    <>
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/destination" element={<Destination />} />
        <Route path="/tours" element={<Tours />} />
        <Route path="/about" element={<About />} />
        <Route path="/tours/package-detail" element={<PackageDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="admin/login" element={<Login />} />
        <Route path="admin/login/forgot" element={<ForgotPassword />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
      <ScrollToTopButton />
      <Footer />
    </>


  );
}

export default App;
